import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import { useThemeStyles } from '../../utils/useThemeStyles';
// eslint-disable-next-line no-unused-vars
import { PaginationProps } from 'antd/lib/pagination';

interface IPaginationProps extends PaginationProps {
  className?: string;
}

export const Pagination: React.FC<IPaginationProps> = (props) => {
  const classes = useThemeStyles('Pagination');

  const className = props.className ? props.className : '';

  return <AntdPagination {...props} className={`${className} ${classes.pagination} `} />;
};
