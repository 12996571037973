import * as React from 'react';
import { useTranslation } from '../../utils/useTranslation';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { CreditTableContainer } from '../../containers/CreditTable';
import { DisplayModeType } from '@brainysoft/lk-components';
import { useClient } from '../../queries/useClient';
import { AppContext } from '@brainysoft/lk-components';

export const IndexView: React.FC = () => {
  const layoutStyles = useThemeStyles('Layout');
  const clientQuery = useClient();
  const client = clientQuery.data;

  const { state } = AppContext.useContainer();
  const mode = state.displayMode;

  const { t } = useTranslation();

  return (
    <React.Fragment>
      {/*<React.Fragment>*/}
      {/*</React.Fragment>*/}
      {(mode === DisplayModeType.DESKTOP || mode === DisplayModeType.WIDESCREEN) && (
        <div className={layoutStyles.greetingWrapper}>
          {t('greeting')}
          {client && client.firstName ? `, ${client.firstName}` : ''}!
        </div>
      )}
      <div className={layoutStyles.pageWrap}>
        <div className={layoutStyles.pageContent}>
          <CreditTableContainer shortTable={true} rows={8} showHeader={true} />
        </div>
      </div>
    </React.Fragment>
  );
};
