import React from 'react';
import { CreditTable } from '../../components/CreditTable';
import { useCredits } from '../../queries/useCredits';

interface IProps {
  rows?: number;
  shortTable?: boolean;
  showHeader?: boolean;
}

export const CreditTableContainer: React.FC<IProps> = (props) => {
  const creditsQuery = useCredits();

  const credits = creditsQuery.data ?? [];

  return <CreditTable {...props} credits={credits} isLoading={creditsQuery.isLoading} />;
};
