import * as React from 'react';
import { NODE_ENV_PRODUCTION, TENANT_DOMAIN, INTEGRATIONS } from '../config';
import { HTMLComment } from '../components/HTMLComment/HTMLComment';
import Script from 'next/script';

export const Chatra: React.FC = (props) => {
  const token = INTEGRATIONS['chatra'] ?? null;

  if (!token) return <></>;
  if (!NODE_ENV_PRODUCTION) return <></>;

  console.log('Chatra initialised');

  return (
    <React.Fragment>
      {/*<HTMLComment comment={"Chatra\n {literal}\n\n"}/>*/}
      <Script id='chatra' strategy='lazyOnload'>
        {`(function(d, w, c) {
                    w.ChatraID = '${token}';
                    var s = d.createElement('script');
                    w[c] = w[c] || function()

                    { (w[c].q = w[c].q || []).push(arguments); }
                    ;
                    s.async = true;
                    s.src = 'https://call.chatra.io/chatra.js';
                    if (d.head) d.head.appendChild(s);
                    })(document, window, 'Chatra');
                `}
      </Script>
      {/*<HTMLComment comment={"Chatra\n {/literal}\n"}/>*/}
    </React.Fragment>
  );
};
