import * as React from 'react';
import Layout from '../src/layouts/main';
import { NextPage } from 'next';
import { useActivePage } from '../src/utils/useActivePage';
import { IndexView } from '../src/views/IndexView';
import { Chatra } from '../src/integrations/Chatra';
import { PixelKbki } from '../src/integrations/PixelKbki';
import { useTranslation } from '../src/utils/useTranslation';

const IndexPage: NextPage = () => {
  useActivePage('main');
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <PixelKbki />
      <Chatra />

      <Layout title={t(`pages:index.title`)}>
        <IndexView />
      </Layout>
    </React.Fragment>
  );
};

// // eslint-disable-next-line no-unused-vars
// IndexPage.getInitialProps = async (ctx) => {
//     // Сервер
//     const res = await creditService.getActive();
//     if (res?.uuid) {
//         if (ctx.res) {
//             ctx.res.writeHead(302, { Location: '/active' });
//             ctx.res.end();
//         }
//     }
//     return {};
// };

export default IndexPage;
