import * as React from 'react';
import { useTranslation } from '../../utils/useTranslation';
import { useThemeStyles } from '../../utils/useThemeStyles';
// eslint-disable-next-line no-unused-vars
import { Dropdown, Menu } from 'antd';
import { EntityType } from '@brainysoft/lk-components';
import { Button } from '../Button';
import { DEFAULT_PAGINATION_PAGE_SIZE } from '../../const';
import { Pagination } from '../Pagination';
import { CreditTableContent } from './CreditTableContent';
import { Icon } from '../Icon';
// eslint-disable-next-line no-unused-vars
import { CreditT } from '@brainysoft/lk-components';

interface IProps {
  credits: CreditT[];
  isLoading: boolean;
  rows?: number;
  shortTable?: boolean;
  showHeader?: boolean;
}

export const CreditTable: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('CreditTable');
  const paginationClasses = useThemeStyles('Pagination');
  const layoutClasses = useThemeStyles('Layout');
  const { t } = useTranslation();

  const [listData, setListData] = React.useState<CreditT[]>([]);
  const [filter, setFilter] = React.useState<EntityType>(EntityType.ALL);
  const [buttonText, setButtonText] = React.useState('');
  const [paginationPageSize, setPaginationPageSize] = React.useState(DEFAULT_PAGINATION_PAGE_SIZE);
  const [pagination, setPagination] = React.useState({
    min: 0,
    max: paginationPageSize,
    total: paginationPageSize,
  });

  React.useEffect(() => {
    if (props.rows && props.rows > DEFAULT_PAGINATION_PAGE_SIZE) {
      setPaginationPageSize(props.rows);
    }
  }, []);

  React.useEffect(() => {
    filterData(filter);
    filterText(filter);
  }, [props.credits]);

  const isLimited = props.shortTable && props.rows;
  const data = isLimited ? listData.slice(0, props.rows) : listData;
  const showButtons = listData.length > data.length;

  const handleFilter = (type) => {
    setFilter(type);
    filterData(type);
    filterText(type);
  };

  const filterText = (type) => {
    const text = type == EntityType.ALL ? t('creditTable:filters') : t(`enums:EntityType.${type}`);
    setButtonText(text);
  };

  const filterData = (type) => {
    let data = props.credits;
    if (type !== EntityType.ALL) {
      data = data.filter((item) => item?.type == type);
    }
    setListData(data);

    if (!isLimited) {
      const min =
        pagination.min < data.length
          ? pagination.min
          : Math.floor(data.length / paginationPageSize) * paginationPageSize;
      const max = !data.length || pagination.max < data.length ? pagination.max : data.length;

      setPagination({
        min: min,
        max: max,
        total: data.length,
      });
    }
  };

  const filters = (
    <Menu onClick={(e) => handleFilter(e.key)}>
      <Menu.Item key={EntityType.ALL}>{t(`enums:EntityType.${EntityType.ALL}`)}</Menu.Item>
      <Menu.Item key={EntityType.LEAD}>{t(`enums:EntityType.${EntityType.LEAD}`)}</Menu.Item>
      <Menu.Item key={EntityType.LOAN_APP}>{t(`enums:EntityType.${EntityType.LOAN_APP}`)}</Menu.Item>
      <Menu.Item key={EntityType.CONTRACT}>{t(`enums:EntityType.${EntityType.CONTRACT}`)}</Menu.Item>
    </Menu>
  );

  const onPaginationChange = (page, pageSize) => {
    const min = (page - 1) * pageSize;
    const max = page * pageSize > data.length ? data.length : page * pageSize;

    setPagination({
      min: min,
      max: max,
      total: data.length,
    });
  };

  return (
    <React.Fragment>
      {!!props.showHeader && (
        <React.Fragment>
          <div className={classes.headerWrapper}>
            <h3 className={layoutClasses.tableTitle}>{t('creditTable:title')}</h3>
            <div className={classes.filterWrapper}>
              <Dropdown trigger={['click']} overlay={filters} placement='bottomRight' overlayClassName='table-filters'>
                <Button shape='round' icon={<Icon type='filter' />} size='small'>
                  {buttonText}
                </Button>
              </Dropdown>
            </div>
          </div>
        </React.Fragment>
      )}

      <CreditTableContent data={data.slice(pagination.min, pagination.max)} isLoading={props.isLoading} />

      {!isLimited && !!data.length && (
        <div className={paginationClasses.paginationWrapper}>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={paginationPageSize}
            total={data.length ? data.length : paginationPageSize}
            onChange={onPaginationChange}
            showSizeChanger={false}
          />
        </div>
      )}

      {showButtons && (
        <div className='flex-end button-group'>
          {(filter == EntityType.LEAD || filter == EntityType.LOAN_APP || filter == EntityType.ALL) && (
            <Button size='small' href={'/loan-app'}>
              {t('creditTable:buttons.allLoans')}
            </Button>
          )}
          {(filter == EntityType.CONTRACT || filter == EntityType.ALL) && (
            <Button size='small' href={'/contracts'} style={{ marginLeft: '.5rem' }}>
              {t('creditTable:buttons.allContracts')}
            </Button>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
