import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { TagProps } from 'antd/lib/tag';
import { Tag as AntdTag } from 'antd';

interface ITagProps extends TagProps {
  className?: string;
}

export const Tag: React.FC<ITagProps> = (props) => {
  const classes = useThemeStyles('Tag');

  const className = props.className ? props.className : '';

  return <AntdTag {...props} className={`${className} ${classes.tag} `} />;
};
