import * as React from 'react';
import { NODE_ENV_PRODUCTION, TENANT_DOMAIN, INTEGRATIONS } from '../config';
import Head from 'next/head';

export const PixelKbki: React.FC = (props) => {
  const token = INTEGRATIONS['kbki'] ?? null;

  if (!token) return <></>;
  if (!NODE_ENV_PRODUCTION) return <></>;

  console.log('Kbki initialised');

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                            var pixel_partner_id = ${token};
                            var pixel_partner_uid = null;
                            pixel_stat = document.createElement('script');
                            pixel_stat.type = 'text/javascript';
                            pixel_stat.async = true;
                            pixel_stat.src = '//pixel.kbki.ru/pixel.js';
                            pixel_stat_s = document.getElementsByTagName('script')[0];
                            pixel_stat_s.parentNode.insertBefore(pixel_stat, pixel_stat_s);
                            console.log('custom head script loaded, customer: monedo')
                        `,
        }}
      />
    </Head>
  );
};
