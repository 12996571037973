import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { Tag } from '../Tag';
import { CreditStatusType } from '@brainysoft/lk-components';
import { useTranslation } from '../../utils/useTranslation';

interface IProps {
  entity: any;
}

export const CreditStatus: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const classes = useThemeStyles('CurrentStatus');

  const { entity } = props;

  return (
    <Tag className={`${classes.tag} multiline`} color={getStatusColor(entity?.status)}>
      {t(`enums:CreditStatusType.${entity?.status}`)}
    </Tag>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case CreditStatusType.CLOSED:
      return 'green';
    case CreditStatusType.APPROVED:
    case CreditStatusType.ISSUED:
      return 'blue';
    case CreditStatusType.SOLD:
      return 'purple';
    case CreditStatusType.DENIED:
    case CreditStatusType.CLIENT_REFUSAL:
    case CreditStatusType.DELINQUENCY:
      return 'red';
    case CreditStatusType.TECH_FAULT:
      return 'grey';
    default:
      return 'orange';
  }
};
