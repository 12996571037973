import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useTranslation } from '../../utils/useTranslation';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { CreditT, EntityType } from '@brainysoft/lk-components';
import { dateParser } from '../../helpers/dateHelper';
import { Currency } from '../Currency';
import { CreditStatus } from '../CreditStatus';
import { LoadingSpinner } from '../LoadingSpinner';

interface ICreditTableContent {
  data: CreditT[];
  isLoading: boolean;
}

export const CreditTableContent: React.FC<ICreditTableContent> = (props) => {
  const classes = useThemeStyles('CreditTable');
  const layoutClasses = useThemeStyles('Layout');
  const { t } = useTranslation();
  const router = useRouter();

  const data = props.data ? props.data : [];

  const handleClick = (item) => {
    switch (item.type) {
      case EntityType.CONTRACT:
        router.push('/contracts/[id]', `/contracts/${item.entities.contractId}`);
        break;
    }
  };

  return (
    <div className={layoutClasses.tableWrapper}>
      <table className={classes.table}>
        {!!data.length && (
          <thead className={classes.tableHeader}>
            <tr>
              <th style={{ textAlign: 'left', paddingLeft: '1rem' }}>{t('creditTable:content.type')}</th>
              <th>{t('creditTable:content.date')}</th>
              <th>{t('creditTable:content.amount')}</th>
              <th>{t('status')}</th>
              {/*<th>Действия</th>*/}
            </tr>
          </thead>
        )}
        <tbody className={classes.tableBody}>
          {data.map((item, index) => {
            const clickable = item?.type === EntityType.CONTRACT ? 'clickable' : '';

            return (
              <tr key={index} onClick={() => handleClick(item)} className={clickable}>
                <td className={classes.entityType}>
                  <span className={classes.cell}>
                    {item?.type !== EntityType.CONTRACT && t(`entities:${item?.type}`)}
                    {item?.type === EntityType.CONTRACT && (
                      <React.Fragment>
                        {t(`entities:${item?.type}`)}{' '}
                        <Link href={'/contracts/[id]'} as={`/contracts/${item?.entities?.contractId}`}>
                          <a className={classes.numberCell}>{`№ ${item?.data?.name}`}</a>
                        </Link>
                      </React.Fragment>
                    )}
                  </span>
                </td>
                <td>
                  <span className={[classes.mobileonly, classes.mobileHeaderItem].join(' ')}>{t('date')}</span>
                  <span className={classes.dateCell}>{dateParser(item.creationDate, true)}</span>
                </td>
                <td className={classes.currencyCell}>
                  <span className={[classes.mobileonly, classes.mobileHeaderItem].join(' ')}>{t('sum')}</span>
                  {!!item?.data?.amount && <Currency amount={item?.data?.amount} size='1.375rem' ratio={0.75} />}
                  {!item?.data?.amount && '–'}
                </td>
                <td className={[classes.currentStatusCell, classes.lastCell].join(' ')}>
                  <CreditStatus entity={item} />
                </td>
              </tr>
            );
          })}
          {!data.length && (
            <tr>
              <td className={classes.lastCell} colSpan={4}>
                <span className={classes.noData}>
                  {props.isLoading && <LoadingSpinner size={8} margin={4} />}
                  {!props.isLoading && t('creditTable:noData')}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
